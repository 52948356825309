.Main-Options {
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle at 300%, #0f142b 10%, #f42c74 10%, #0f142b 100%);
  overflow: hidden;
}

.Main-Options-Container {
  width: 100%;
  margin-top: 25vh;
  font-family: "Montserrat";
}

.Main-Options-Inner {
  width: 60%;
  margin-top: 25vh;
  margin-left: 20%;
  font-family: "Montserrat";
}

.Main-Options-Row {
  width: 100%;
  margin: 50px 0px;
}

.Main-Options-Flex {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.Main-Options-App {
  height: 50px;
}

.Main-Options-Button {
  text-align: center;
  border: 2px solid #f52c74;
  border-radius: 20px;
  font-size: 18pt;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  transition: .25s all ease;
}

.Main-Options-Button-Fill {
  background: linear-gradient(to bottom, #f52c74, #390417);
}

.Main-Options-Button-Fill-Dark {
  background: linear-gradient(to bottom, #190244, #190753);
}

.Main-Options-Button-Skip {
  width: 70%;
  margin-left: 15%;
  box-shadow: 0 0 8px #f52c74;
  font-size: 11pt;
  padding: 5px;
}

.Main-Options-Button:hover {
  box-shadow: 0 0 8px #fff;
}

.App-Options-Button input {
  display: none
}

.Main-Options-Text {
  font-size: 14pt;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
}

.Main-Options-Link {
  text-decoration: underline;
  cursor: pointer;
  transition: .25s all ease;
  text-shadow: 1px 0px 1px #b3b3b3;
}

.Main-Options-Link-Red {
  color: #f52c74;
}

.Main-Options-Link-Blue {
  color: #190753;
}

.Main-Options-Link:hover {
  font-size: 16pt;
}

.Main-Footer {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  bottom: 0px;
  width: 100%;
  text-align: right;
}

.Main-Legal {
  font-size: 10pt;
  color: #fff;
  text-decoration: underline;
  padding: 10px;
  transition: .25s all ease;
  cursor: pointer;
}

.Main-Legal:hover {
  color: blue;
}