.SmallCard-Container {
    position: relative;
    height: 250px;
    width: 175px;
    margin: 1px;
    border-radius: 15px;
    font-family: 'Montserrat';
    z-index: 1;
}

.SmallCard-Color {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    border-radius: 15px;
}

.SmallCard-event {
    border: 2px solid #f52c74;
    background: linear-gradient(to bottom right, #d233c2, #390417);
}

.SmallCard-venue {
    border: 2px solid #6e4ae0;
    background: linear-gradient(to bottom right, #704ce3, #190753);
}

.SmallCard-contributor {
    border: 2px solid #d233c2;
    background: linear-gradient(to bottom right, #d233c2, #390417);
}

.SmallCard-Disabled {
    border: 2px solid #3d3d3d;
    background: linear-gradient(to bottom right, #3d3d3d, #363636);
}

.SmallCard-default {
    border: 2px solid #3d3d3d;
    background: linear-gradient(to bottom right, #3d3d3d, #363636);
}

.SmallCard-Row {
    width: 100%;
    min-height: 36px;
    display: flex;
    justify-content: space-between;
    color: #b3b3b3;
    padding: 5px 5px;
}

.SmallCard-Header {
    width: 100%;
    height: 100px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    z-index: 1;
}

.SmallCard-Heart {
    margin-top: -10px;
    font-size: 18pt;
    color: #fff;
}

.SmallCard-Dismiss {
    margin-top: -10px;
    font-size: 22pt;
    color: #fff;
    cursor: pointer;
    transition: .25s all ease;
}

.SmallCard-Dismiss:hover {
    color: #ff0000;
}

.SmallCard-Accept {
    margin-top: -10px;
    font-size: 22pt;
    color: #fff;
    cursor: pointer;
    transition: .25s all ease;
}

.SmallCard-Accept:hover {
    color: rgb(1, 77, 1);
}

.SmallCard-Icon {
    font-size: 14pt;
    color: #fff;
    margin-right: 5px;
}

.SmallCard-Text {
    font-size: 12pt;
    color: #fff;
}

.SmallCard-Footer {
    width: 100%;
    height: 150px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 1;
    padding-top: 40%;
}

.SmallCard-Flex {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
}

.SmallCard-BG {
    position: relative;
    height: 250px;
    top: -250px;
    z-index: -1;
    opacity: .6;
}

.SmallCard-BG-Icon {
    font-size: 48pt;
    color: #fff;
    width: 100%;
    text-align: center;
    padding-top: 60px;
}

.SmallCard-Inactive {
    position: relative;
    font-size: 24pt;
    margin-left: 10px;
    margin-top: -380px;
    z-index: 2;
    color: #fff;
    rotate: -45deg;
    opacity: .5;
}

.SmallCard-Rating {
    position: relative;
    top: -350px;
    width: 100%;
    height: 50px;
    font-size: 18pt;
    padding: 0px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.SmallCard-Star-Selected {
    margin-top: -10px;
    color: yellow;
}

.SmallCard-Star-Unselected {
    margin-top: -10px;
    color: #b3b3b3;
}

.SmallCard-CI {
    height: 246px;
    border-radius: 15px;
    object-fit: cover;
}

.SmallCard-Stars {
    font-size: 22pt;
    color: yellow;
    margin-top: -12px;
}

.SmallCard-Stars-Text {
    margin-left: -23px;
    font-size: 9pt;
    font-weight: bold;
    margin-top: 4px;
    color: #000;
}

.SmallCard-Image {
    position: relative;
    height: 250px;
    width: 175px;
    margin: 1px;
    border-radius: 15px;
    font-family: 'Montserrat';
    z-index: 1;
    border: 1px solid #fff;
}