.Giglit-Container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;    
}

.Giglit-Default {
    background: radial-gradient(circle at 300%, #3d3d3d 10%, #fff 10%, #3d3d3d 100%);
}

.Giglit-events {
    background: radial-gradient(circle at 300%, #0f142b 10%, #f42c74 10%, #0f142b 100%);
}

.Giglit-venues {
    background: radial-gradient(circle at 300%, #000, #704ce3 10%, #0f142b 80% );
}

.Giglit-Splash-Container {
    position: absolute;
    top: 30vh;
    width: 100%;
    height: calc(100vh - 30vh);
    overflow: hidden;
}

.Giglit-Splash-Text {
    font-size: 36pt;
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
    width: 350px;
}

.Giglit-Splash-Image {
    position: absolute;
    left: calc(100% - 270px);
    top: 200px;
}

.Giglit-Choose-Container {    
    position: absolute;
    top: 0vh;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.Giglit-Choose-Text {
    font-size: 32pt;
    font-weight: bold;
    color: #fff;
    width: 100%;
    text-align: center;
    padding: 3%;
    margin-top: 30vh;
}

.Giglit-Row-Flex {
    justify-content: space-around;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 10px;
}

.Giglit-Flex {
    display: flex;
}

.Giglit-Choose-Button {
    width: 150px;
    font-size: 24pt;
    text-align: center;
    color: #fff;
    border-radius: 25px;
    transition: .25s all ease;
    cursor: pointer;
    margin: 5px;
}

.Giglit-Choose-Button:hover {
    border: 2px solid #fff;
    box-shadow: 0 0 8px #000;
}

.Giglit-Card-CI {
    position: relative;
    top: -41px;
    height: 100vh;
    z-index: 0;
    object-fit: cover;
}

.Giglit-Next {
    position: absolute;
    top: 40vh;
    width: 100%;
    text-align: right;
    padding-right: 10px;
    z-index: 2;
}

.Giglit-Back {
    position: absolute;
    top: 0px;
    width: 100%;
    padding-left: 10px;
    z-index: 2;
}

.Giglit-Arrow {
    width: 55px;
    height: 55px;
    font-size: 36pt;
    border: 2px solid #000;
    border-radius: 50%;
    background-color: #868585;
    color: #fff;
    text-align: center;
    opacity: .7;
    padding: 1px;
    cursor: pointer;
    transition: .25s all ease;
}

.Giglit-Arrow:hover {
    color: #000;
}

.Giglit-Details {
    position: absolute;
    top: 55vh;
    width: 100%;
    z-index: 2;
}

.Giglit-Addr {
    max-width: 98%;
    font-size: 12pt;
    color: #fff;
    display: flex;
    border-radius: 25px;
    border: 2px solid;
    cursor: pointer;
    padding: 5px;
    text-shadow: 0 0 5px #000;
}

.Giglit-Name {
    font-size: 18pt;
    color: #fff;
    text-decoration: underline;
    text-shadow: 0 0 5px #000;
}

.Giglit-Cat {
    font-size: 11pt;
    color: #fff;
    text-shadow: 0 0 5px #000;
}

.Giglit-More {
    font-size: 10pt;
    color: #fff;
    display: flex;
    border-radius: 25px;
    border: 1px solid;
    cursor: pointer;
    padding: 5px;
}

.Giglit-Dist {
    font-size: 10pt;
    color: #fff;
    display: flex;
}

.Giglit-Date {
    font-size: 12pt;
    color: #fff;
}

.Giglit-Addr-Icon {
    margin-left: 5px;
    margin-right: 5px;
    color: #fff;
}

.Giglit-Weather-Img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #190244;
    margin-right: 5px;
}

.carousel-indicators {
    left: 10px;
    bottom: 0px;
}

.carousel-indicators button {
    margin-right: 10px;
    border-radius: 50%;
}

.carousel-indicators .active {
    background-color: #190244;
    margin-right: 10px;
}