.List-Container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    background: radial-gradient(circle at 250%, #0f142b 10%, #f42c74 10%, #0f142b 80%);
}

.List-Cards {
    position: fixed;
    top: 50px;
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 120px;
    padding-top: 10px;
    overflow-y: auto;
}

.form-check {
    padding-left: 25px;
    color: #b3b3b3;
}

.form-check input {
    margin-left: -15px;
    margin-top: 5px;
}

.List-More-Container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 10px;
}

.List-More-Divider {
    flex-grow: 100;
    height: 1px;
    border: 1px solid #b3b3b3;
    margin: 10px 10px;
}

.List-More-Button {
    border: 1pt solid #b3b3b3;
    color: #b3b3b3;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    font-size: 12pt;
    transition: .25s all ease;
}

.List-More-Button:hover {
    box-shadow: 0 0 5px #fff;
}

.List-NoResults-Container {
    width: 100%;
    color: #fff;
}

.List-NoResults-Icon {
    font-size: 26pt;
    width: fit-content;
    margin: 0 auto;
}

.List-NoResults-Text {
    text-align: center;
    width: 200px;
    margin: 0 auto;
    font-size: 24pt;
    font-weight: bold;
    font-style: italic;
    text-shadow: 5px 5px 8px #f52c74;
}

.List-NoResults-Text-2 {
    width: 300px;
    margin: 20px auto;
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
}