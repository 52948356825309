.Legal-Link {
    font-size: 8pt;
}

.Legal-Link:hover {
    cursor: pointer;
}

.Legal-Container {
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 5px;
}

.Legal-Img {
    height: 30px;
    cursor: pointer;
}

.card-body {
    overflow-y: auto;
    height: calc(100vh - 100px);
}

.card-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 10px);
    height: 45px;
    background-color: #f0eded;
}

.card-header {
    height: 45px;
}