.App-Loading-Bar {
  position: relative;
  margin-top: 50%;
  background-color: #190244;
  transition: .20s all ease-in
}

.App-Loading-Bar-Init {
  height: 5px;
  width: 0;
}

.App-Loading-Bar-Final {
  height: 5px;
  width: 100%;
}

.App-Loading-Bar-Post {
  margin-top: 0;
  width: 100%;
  height: 100vh;
}

.App-Faded {
  opacity: 0;
}

.App-Showing {
  opacity: 1;
}

.Gigloca-Container {
  width: 100vw;
  height: 100vh;  
  background-color: #000;
}


.Gigloca-Title {
  position: absolute;
  top: 17vh;
  color: #fff;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.Gigloca-Text-1 {
  position: absolute;
  top: 60vh;
  color: #f52c74;
  font-size: 16pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-style: italic;  
  text-shadow: 5px 5px 8px #000;
}

.Gigloca-Text-1b {
  position: absolute;
  top: 70vh;
  color: #f52c74;
  font-size: 16pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-style: italic;  
  text-shadow: 5px 5px 8px #000;
}


.Gigloca-Text-2 {
  position: absolute;
  top: 75vh;
  color: #fff;
  font-size: 20pt;
  width: 100%;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.Gigloca-Image {
  transition: .25s all ease;
}

.Gigloca-Image-Start {
  width: 40%;  
}

.Gigloca-Image-End {
  width: 90%;
}

.Gigloca-Row {
  position: absolute;
  top: 75vh;
  width: 100%;
  margin-bottom: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

.Gigloca-Flex-Spread {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.Gigloca-Button-App {
  height: 50px;
  width: auto;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background: none;
  border-radius: 15px;
  box-shadow: inset 0 0 3px #000;
}

::-webkit-scrollbar-thumb {
  background: #190244;
  border-radius: 15px;
}