.Node-Container {
    position: absolute;
    top: 50px;
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: hidden;
}

.Node-Outer {
    max-width: 700px;
    margin-left: calc((100% - 700px)/2)
}

.Empty-Container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

.Node-fan {
    background: radial-gradient(circle at 300%, #000, #704ce3 10%, #0f142b 80%);
}

.Node-Venue {
    background: radial-gradient(circle at 300%, #000, #704ce3 10%, #0f142b 80%);
}

.Node-Contributor {
    background: radial-gradient(circle at 300%, #390417 10%, #d233c2 10%, #390417 100%);
}

.Node-Event {
    background: radial-gradient(circle at 300%, #0f142b 10%, #f42c74 10%, #0f142b 100%);
}

.Node-Tix {
    background: radial-gradient(circle at 150%, #4a5524 10%, #bfe001 10%, #63722d 100%);
}

.Node-Check {
    background: radial-gradient(circle at 150%, #3d3d3c 10%, #d6d6d5 10%, #3d3d3c 100%);
    padding-bottom: 50px;
}

.Node-Carousel {
    width: 100%;
    height: 250px;
}

.Node-CI {
    width: 100%;
    height: 248px;
    border-radius: 15px;
    object-fit: cover;
    border: 2px solid #704ce3;
}

.Node-BG-Icon {
    font-size: 48pt;
    color: #fff;
    width: 100%;
    text-align: center;
    padding-top: 80px;
}

.Node-Back {
    position: absolute;
    top: 0px;
    width: 100%;
    max-width: 700px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
}

.Node-Arrow {
    width: 55px;
    height: 55px;
    font-size: 32pt;
    border: 2px solid #000;
    border-radius: 50%;
    background-color: #868585;
    color: #fff;
    cursor: pointer;
    transition: .25s all ease;
    text-align: center;
    opacity: .7;
    padding: 1px;
}

.Node-Arrow-2 {
    width: 48px;
    height: 48px;
    font-size: 28pt;
    padding-left: 2px;
    margin-right: 5px;
}

.Node-Arrow:hover {
    color: #b3b3b3;
    opacity: 1;
}

.Node-Content {
    width: 100%;
    position: relative;
    height: calc(100vh - 400px);
    overflow-y: auto;
    font-family: 'Lato';
    color: #b3b3b3;
    padding-bottom: 100px;
}

.Node-Content-2 {
    width: 100%;
    position: relative;
    top: 0px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    font-family: 'Lato';
    color: #b3b3b3;
    padding-bottom: 100px;
}

.Node-Content-3 {
    font-family: 'Lato';
    color: #b3b3b3;
}

.Node-Form {
    position: relative;
    top: 0px;
    margin: 0 auto;
    height: calc(100vh - 164px);
    overflow-y: auto;
    padding-bottom: 60px;
}

.Node-Row {
    width: 100%;
    margin-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
}

.Node-Row-Thin {
    width: 100%;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.Node-Flex-Spread {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.Node-Flex-Wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Node-Flex {
    display: flex;
}

.Node-Flex-Left {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.Node-Flex-Left-NoWrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
}

.Node-Title {
    font-size: 22pt;
}

.Node-Addr {
    width: fit-content;
    font-size: 11pt;
    color: #fff;
    display: flex;
    background-color: #190244;
    border-radius: 25px;
    border: 2px solid #f42c74;
    cursor: pointer;
    padding: 5px;
    text-shadow: 0 0 5px #000;
}

.Node-Addr-Icon {
    color: #fff;
    font-size: 12pt;
    margin-left: 5px;
    margin-right: 5px;
}

.Node-Item {
    display: flex;
    border: 2px solid;
    border-radius: 25px;
    padding: 5px;
    margin: 5px;
    font-size: 8pt;
}

.Node-Divider-Text {
    margin-right: 10px;
    margin-top: 0px;
    height: 20px;
    font-size: 14pt;
    color: #b3b3b3
}

.Node-Divider {
    flex-grow: 100;
    height: 1px;
    border: 1px solid #b3b3b3;
    margin-top: 10px;
    margin-right: 10px;
}

.Node-Divider-Button {
    border: 1pt solid #b3b3b3;
    color: #b3b3b3;
    border-radius: 25px;
    padding: 5px;
    cursor: pointer;
    font-size: 8pt;
    transition: .25s all ease;
}

.Node-Divider-Button:hover {
    box-shadow: 0 0 5px #fff;
}

.Node-Details {
    padding: 5px;
    font-size: 11pt;
}

.Node-Card {
    z-index: 1;
}

.Node-Stars {
    font-size: 22pt;
    color: yellow;
    margin-top: -13px;
}

.Node-Stars-Text {
    font-size: 9pt;
    font-weight: bold;
    margin-top: 0px;
    margin-left: 6px;
    margin-top: -26px;
    color: #000;
}

.Node-Heart {
    font-size: 22pt;
    color: #f52c74;
}

.Node-Heart-Followers {
    margin-top: -13px;
    margin-right: 8px;
}

.Node-Heart-Text {
    font-size: 9pt;
    font-weight: bold;
    margin-top: -28px;
    margin-left: 2px;
    width: 25px;
    color: #000;
    text-align: center;
}

.Node-Logo {
    margin-top: 50px;
    width: 100%;
}

.Node-Gigin {
    font-size: 14pt;
    width: 200px;
    padding: 5px;
    border: 1px solid #704ce3;
    background: linear-gradient(to bottom, #704ce3, #190364);
    border-radius: 25px;
    color: #fff;
    text-align: center;
}

.Node-Gigin:hover {
    border: 1px solid #704ce3;
    background: linear-gradient(to bottom, #190364, #704ce3);
    box-shadow: 0 0 5px #fff;
}

.Node-Summary {
    font-size: 14pt;
    text-align: center;
}

.Node-Cards {
    position: relative;
    top: 0px;
    width: 100%;
    height: calc(100vh - 100px);
    padding-bottom: 60px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Node-Link {
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;
}

.Node-Link:hover {
    text-shadow: 0 0 5px navy;
}
.carousel-inner {
    border-radius: 15px !important;
}

.carousel-indicators {
    left: 10px;
    bottom: 0px;
}

.carousel-indicators button {
    margin-right: 10px;
    border-radius: 50%;
}

.carousel-indicators .active {
    background-color: #190244;
    margin-right: 10px;
}

.Pref-Container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
}

.Pref-Content {
    position: relative;
    top: -250px;
    width: 100%;
    height: calc(100vh - 69px);
    overflow-y: auto;
    font-family: 'Lato';
    color: #b3b3b3;
}

.Pref-Bounce {
    width: 300px;
    padding: 10px;
    text-align: center;
    margin: 20px auto;
    font-size: 24pt;
    font-weight: bold;
    height: 100vh;
    overflow: hidden;
}

.Pref-Back {
    position: relative;
    height: 64px;
    top: 0px;
    width: 100%;
    padding-left: 10px;
    z-index: 1;
}

.Pref-Clickable {
    transition: .25s all ease;
    cursor: pointer;
}

.Pref-Clickable:hover {
    color: #777;
}

.Pref-Img-BG {
    opacity: .3;
}

.Pref-Profile-Img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 0 8px #fff;
    object-fit: cover;
}

.Pref-Profile-Icon {
    font-size: 48pt;
    color: #fff;
    width: 100px;
    height: 100px;
    border: 2px solid #fff;
    box-shadow: 0 0 8px #fff;
    border-radius: 50%;
    text-align: center;
}

.Pref-Profile-Icon svg {
    margin-top: -20px;
}

.Pref-Edit-Icon {
    margin-top: 60px;
    margin-left: -10px;
    font-size: 22pt;
    color: #fff;
}

.Pref-Edit-Icon input {
    display: none;
}

.Pref-Name {
    font-size: 18pt;
    color: #fff;
}

.Pref-Form {
    position: relative;
    background: linear-gradient(to bottom, #f52c74, #390417);
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 15px;
    overflow-y: auto;
    max-height: 600px;
}

.Pref-Text {
    font-size: 11pt;
    margin-top: 3px;
}

.Pref-Label {
    font-size: 14pt;
    color: #fff;
    cursor: pointer;
}

.Pref-Link {
    margin-top: 3px;
    font-size: 9pt;
    color: #fff;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;
}

.Pref-Link:hover {
    color: navy;
    text-shadow: 0 0 5px #fff;
}

.Pref-Cancel-Button {
    width: 100%;
    text-align: center;
    border: 2px solid #b3b3b3;
    background: linear-gradient(to bottom, #b3b3b3, #424141);
    border-radius: 20px;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fff;
    padding: 10px;
    transition: .25s all ease;
    cursor: pointer;
}

.Pref-Button {
    width: 45%;
    text-align: center;
    border-radius: 20px;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fff;
    padding: 10px;
    transition: .25s all ease;
    cursor: pointer;
}

.Pref-Button-Save {
    background: linear-gradient(180deg, #f52c74, #390417);
    border: 2px solid #f52c74;
}

.Pref-Button-Cancel {
    border: 2px solid #b3b3b3;
    background: linear-gradient(to bottom, #b3b3b3, #424141);
}

.Pref-Button:hover {
    box-shadow: 0 0 8px #fff;
}

.Pref-Node {
    margin: 5px;
    z-index: 3;
}

.My-Container {
    position: relative;
    top: 50px;
    width: 100%;
    height: calc(100vh - 100px);
    overflow-y: hidden;
    padding-bottom: 25px;
}

.My-Link-Text {
    font-size: 16pt;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
    word-wrap: break-word;
    max-width: calc(100% - 70px);
    text-decoration: underline;
}

.My-Link-Icon {
    font-size: 24pt;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
    margin-top: -10px;
}

.My-Link-Icon:hover {
    color: #b3b3b3;
}

.My-Link-Icon-Animate {
    animation: infoFlash 1.2s infinite;
}

.My-Link {
    font-size: 16pt;
    color: #fff;
    width: calc(100% - 30px);
    word-wrap: break-word;
}

.My-Img-Container {
    height: 250px;
    width: 175px;
    object-fit: cover;
    border: 3px solid #b3b3b3;
    border-radius: 15px;
    font-size: 36pt;
    color: #b3b3b3;
    text-align: center;
    cursor: pointer;
    transition: .25s all ease;
    margin-bottom: 5px;
}

.My-Img-Container:hover {
    color: #fff;
}

.My-Img-Container file {
    display: none;
}

.My-Img-Container-Perfect {
    background: linear-gradient(to bottom right, #704ce3, #190753);
}

.My-Img-Container-Good {
    background: linear-gradient(to bottom right, #f1b9f9, #3d3d3d);
}

.My-Img-Container-Bad {
    background: linear-gradient(to bottom right, #3d3d3d, #363636);
}

.My-Slot-Container {
    width: 175px;
}

.My-Slot-Descr {
    width: 100%;
    max-height: 55px;
    font-size: 11pt;
    overflow-y: auto;
    color: #fff;
    padding: 5px;
}

.My-Time {
    font-size: 14pt;
    color: #fff;
}

.My-Time-Day {
    border: 1pt solid #fff;
    border-radius: 15px;
    font-size: 14pt;
    width: 55px;
    height: 30px;
    color: #fff;
    text-align: center;
    margin-right: 5px;
}

.My-User-Image {
    width: 45px;
    height: 45px;
    border: 1pt solid #fff;
    box-shadow: 0 0 5px #fff;
    border-radius: 50%;
    font-size: 24pt;
    text-align: center;
}

.My-Reviews-Container {
    width: 90%;
    margin: 15px auto;
    max-height: 150px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.My-Reviews-More {
    max-height: 600px;
    height: fit-content;
}

.My-Review-Container {
    width: 380px;
    margin: 5px auto;
    max-height: 400px;
    background-color: #0f142b;
    border-radius: 5px;
}

.My-Review-Stars {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    height: 35px;
}

.My-Review-Text {
    width: 100%;
    height: fit-content;
    max-height: 125px;
    overflow-y: auto;
    color: #fff;
    font-size: 10pt;
}

.My-Splash {
    font-size: 16pt;
    color: #fff;
    padding: 8%;
    width: 100%;
    height: calc(100vh - 120px);
    overflow-y: auto;
}

.Event-Weather-Icon {
    height: 35px;
    border: 1px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px #fff;
}

.Event-Weather-Text {
    font-size: 14pt;
    color: #fff;
    margin-left: 5px;
}

.Event-Icon-Container {
    cursor: pointer;
    width: 100px;
    text-align: center;
}

.Event-Icon {
    font-size: 24pt;
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
}

.Event-Icon-Text {
    font-size: 11pt;
    color: #fff;
}

.Post-Delete-Icon {
    width: 32px;
    color: #fff;
    font-size: 24pt;
    margin: 0px auto;
    text-align: center;
    transition: .25s all ease;
    cursor: pointer;
}

.Post-Delete-Icon:hover {
    color: #b80404;
}

.Rate-Container {
    position: relative;
    width: 350px;
    height: 280px;
    margin-left: calc((100% - 350px)/2);
    border: 2px solid yellow;
    border-radius: 15px;
    box-shadow: 5px 5px 8px #000;
    background-color: #b3b3b3;
    z-index: 2;
}

.Rate-Here-Text {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: .25s all ease;
}

.Rate-Here-Button {
    border: 2px solid #fff;
    background: linear-gradient(to bottom, yellow, #a8a803);
    border-radius: 15px;
    padding: 5px;
    width: 86%;
    margin-left: 7%;
}

.Rate-Here-Button:hover {
    background: linear-gradient(to bottom, #a8a803, yellow);
    box-shadow: 0 0 5px #fff;
    border: 2px solid #fff;
}

.View-Content {
    width: 100%;
    position: relative;
    height: calc(100vh - 310px);
    overflow-y: auto;
    font-family: 'Lato';
    color: #b3b3b3;
    padding-bottom: 100px;
}

.Node-Nearby {
    border: 2px solid #f52c74;
    border-radius: 15px;
    padding: 1px 3px;
    height: 29px;
    margin-top: 0px;
    font-size: 12pt;
    color: #fff;
}

.Node-SM-Icons {
    width: 55px;
    height: 55px;
    font-size: 18pt;
    text-align: center;
    border: 1px solid #fff;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px #fff;
    padding: 5px 2px;
    transition: .25s all ease;
    cursor: pointer;
}

.Node-SM-Icons:hover {
    border: 2px solid #b3b3b3;
    color: #b3b3b3;
}

.Node-Close-Container {
    width: 65px;
    height: 70px;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: .25s all ease;
    color: #fff;
    margin-top: 8px;
    margin-left: 5px;
}

.Node-Close-Icon {
    font-size: 24pt;
    width: 100%;
    text-align: center;
}

.Node-Close-Container:hover {
    border: 2px solid #b3b3b3;
    color: #b3b3b3;
    box-shadow: 0 0 5px #fff;
}

.Notifications-Content {
    width: 100%;
    position: relative;
    height: calc(100vh - 150px);
    overflow-y: auto;
    font-family: 'Lato';
    color: #fff;
    padding-bottom: 100px;
}

.Notification-Container {
    width: 380px;
    border: 2px solid #b3b3b3;
    border-radius: 15px;
    box-shadow: 0 0 5px #fff;
}

.Notification-Date-Containter {
    width: 70px;
    font-size: 8pt;
}

.Notification-Date-Row {
    width: 100%
}

.Notification-Msg {
    font-size: 11pt;
    width: 255px;
    white-space: pre-wrap;
}

.Notification-Del-Icon {
    font-size: 24pt;
}

.Node-Msg {
    width: 100%;
    text-align: center;
    font-size: 11pt;
    color: #fff;
    font-style: italic;
    text-shadow: 3px 3px 5px #b3b3b3;
}

.Node-Ping-Button {
    width: 220px;
    margin: 10px auto;
    text-align: center;
    font-size: 12pt;
    border: 3px solid yellow;
    border-radius: 15px;
    cursor: pointer;
    font-weight: bold;
    background: linear-gradient(to bottom right, #704ce3, #190753);
    transition: .25s all ease;
}

.Node-Ping-Button:hover {
    box-shadow: 0 0 8px #fff;
    font-weight: bold;
}

.Node-Slots-Container {
    color: #fff;
    border: 3px solid #190753;
    border-radius: 15px;
    margin: 5px 3px;
    background: linear-gradient(to bottom right, #704ce3, #190753);
}

.Node-Slot-Container {
    margin: 5px 0px;
}

.Node-Slot-Desc {
    text-align: center;
    width: 100%;
    padding: 5px;
    font-size: 11pt;
}

.Node-Confirm {
    position: relative;
    top: -50vh;
    width: 300px;
    margin: 0 auto;
    background-color: #5f5e5e;
    box-shadow: 5px 5px 8px #000;
    border: 3px solid #000;
    border-radius: 15px;
    padding: 5px;
    z-index: 2;
}

.Confirm-Text {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 14pt;
}

.Node-NoResults-Container {
    width: 100%;
    color: #fff;
}

.Node-NoResults-Icon {
    font-size: 26pt;
    width: fit-content;
    margin: 0 auto;
}

.Node-NoResults-Text {
    text-align: center;
    width: 200px;
    margin: 0 auto;
    font-size: 24pt;
    font-weight: bold;
    font-style: italic;
    text-shadow: 5px 5px 8px #f52c74;
}

.Node-NoResults-Text-2 {
    width: 300px;
    margin: 20px auto;
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
}

.Contact-Header {
    margin: 15px;
    font-size: 18pt;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px 5px #ccc;
}

.Contact-Sent {
    font-size: 48pt;
    width: 120px;
    padding-bottom: 15px;
    border: 5px solid green;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 0 8px green;
}

.Contact-Sent-Text {
    font-size: 12pt;
    width: 100%;
    text-align: center;
    color: #fff;
}

.Info-Icon {
    font-size: 14pt;
    color: #fff;
    margin-left: 5px;
    margin-top: -3px;
    cursor: pointer;
    transition: .25s all ease;
    animation: infoFlash 1.2s infinite;
}

.Info-Icon:hover {
    color: #777;
}

.Info-Container {
    position: absolute;
    width: 200px;
    background-color: #5f5e5e;
    box-shadow: 5px 5px 8px #000;
    border: 2px solid #000;
    border-radius: 15px;
    padding: 5px;
    z-index: 4;
}

.Info-Text {
    color: #fff;
    width: 100%;
    text-align: left;
    font-size: 12pt;
    white-space: pre-wrap;
}

.Info-Row {
    width: 100%;
    margin-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.Info-Flex-Wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Info-Button {
    width: 85%;
    text-align: center;
    border-radius: 20px;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fff;
    padding: 10px;
    transition: .25s all ease;
    cursor: pointer;
    background: linear-gradient(180deg, #f52c74, #390417);
    border: 2px solid #f52c74;
}

@keyframes infoFlash {
    from {
        color: #fff;
    }

    50% {
        color: #190244
    }

    to {
        color: #fff
    }
}

.Node-Ownership {
    width: 100%;
    font-size: 11pt;
    color: yellow;
    font-weight: bold;
    text-shadow: 5px 5px 8px #ccc;
    font-style: italic;
    text-align: center;
}

.Node-Add-Header {
    margin-top: 10px;
    width: 100%;
    font-size: 18pt;
    color: #fff;
}

.Node-Add-Text {
    width: 100%;
    font-size: 11pt;
    color: #fff;
    padding-left: 20px;
}

.Tix-Button {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 200px;
    color: #ccc;
    background: linear-gradient(to bottom, #a1faa5, #446e52);
    border-radius: 20px;
    border: 2px solid #000;
    transition: .25s all ease;
    padding: 5px;
    text-align: center;
}

.Tix-Button-Active {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 200px;
    color: #fff;
    background: linear-gradient(to bottom, #49f552, #12612c);
    border-radius: 20px;
    border: 2px solid #000;
    transition: .25s all ease;
    padding: 5px;
    text-align: center;
}

.Tix-Text {
    font-size: 14pt;
    font-weight: bold;
}

.Tix-Icon {
    font-size: 16pt;
}

.Tix-Button-Active:hover {
    background: linear-gradient(to bottom, #12612c, #49f552);
    border: 2px solid #fff;
    box-shadow: 0 0 5px #fff;
}

.Tix-Header {
    font-weight: bold;
    font-size: 16pt;
    color: #fff;
    background: linear-gradient(to bottom, #bfe001, #4a5524);
    border-radius: 5px;
}

.Tix-Label {
    width: 100%;
    font-size: 14pt;
    text-wrap: wrap;
    color: #fff;
}

.Tix-Detail {
    width: 100%;
    font-size: 12pt;
    text-align: center;
    text-wrap: wrap;
    color: #fff;
}

.Tix-Num {
    width: 100%;
    font-size: 12pt;
    text-align: center;
    text-wrap: wrap;
    color: #fff;
}

.Tix-Icon {
    font-size: 18pt;
    transition: .25s all ease;
    margin: 0px 5px;
    color: #fff;
    margin-top: -3px;
}

.Tix-Container {
    position: relative;
    width: 170px;
    margin: 1px;
    border-radius: 15px;
    border: 2px solid #3d3d3d;
    background: linear-gradient(to bottom, #a5a3a3, #363636);
}

.Tix-Container-1 {
    height: 300px;
}

.Tix-Container-2 {
    height: 225px;
}

.Tix-Footer {
    position: absolute;
    bottom: 0px;
}

.Tix-Price-1 {
    position: absolute;
    bottom: 175px;
}

.Tix-Price-2 {
    position: absolute;
    bottom: 50px;
}

.Tix-Count-1 {
    position: absolute;
    bottom: 225px;
}

.Tix-Count-2 {
    position: absolute;
    bottom: 125px;
}

.Tix-Limit-1 {
    position: absolute;
    bottom: 200px;
}

.Tix-Limit-2 {
    position: absolute;
    bottom: 100px;
}

.Tix-Cat-1 {
    position: absolute;
    bottom: 150px;
}

.Tix-Request {
    position: relative;
    bottom: 340px;
    width: 300px;
    margin: 0 auto;
    background-color: #5f5e5e;
    box-shadow: 5px 5px 8px #000;
    border: 3px solid #000;
    border-radius: 15px;
    padding: 5px;
    z-index: 2;
}

.MyTix-Container {
    position: relative;
    height: 250px;
    width: 175px;
    margin: 1px;
    border-radius: 15px;
    border: 2px solid #3d3d3d;
    background: linear-gradient(to bottom, #a5a3a3, #363636);
    cursor: pointer;
    transition: .25s all ease;
}

.MyTix-Container:hover {
    border: 2px solid yellow;
    box-shadow: 0 0 5px yellow;
}

.MyTix-Label {
    text-align: center;
    font-size: 14pt;
    color: #fff;
}

.MyTix-QR {
    position: relative;
    font-size: 128px;
    padding-left: 20px;
    bottom: 45px;
}

.MyTix-Tix {
    width: auto;
}

.MyTix-Count {
    height: 30px;
    width: 30px;
    font-size: 14pt;
    color: gold;
    border: 1pt solid gold;
    box-shadow: 0 0 5px gold;
    border-radius: 50%;
    padding: 0px;
    text-align: center;
}

.MyTix-Bar {
    background: linear-gradient(to bottom, #a5a3a3, #363636);
    border-radius: 5px;
    border: 1px solid #000;
}

.Reader-Container {
    max-width: 600px;
    margin: 0 auto;
    border: 5px solid #000;
    box-shadow: 0 0 5px #000;
    border-radius: 5px;
}

.Reader-Button {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    border: 2px solid #b3b3b3;
    border-radius: 20px;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fff;
    padding: 10px;
    transition: .25s all ease;
    cursor: pointer;
}

.Reader-Button-Cancel {
    background: linear-gradient(180deg, #e02020, #390417);
}

.Reader-Button-Scan {
    background: linear-gradient(to bottom, #49f552, #12612c);
}

.Reader-Button:hover{
    box-shadow: 0 0 5px #fff;
}

.QR-Status {
    text-align: center;
    transition: .25s all ease;
}

.QR-Small {
    font-size: 2px;
}

.QR-Big {
    font-size: 128px;
}

.Pay-Small {
    font-size: 2px;
}

.Pay-Big {
    font-size: 18pt;
}

.Receipt-Container {
    width: 100%;
    display: flex;
}

.Receipt-Icon {
    font-size: 16pt;
    margin: 0px 5px;
}

.Receipt-Text {
    font-size: 16pt;
}

.Receipt-Container:hover {

}

.Node-Frame {
    border: 2px solid #704ce3;
    border-radius: 15px;
}

.Node-Email {
    padding: 5px;
    background-color: #0f142b;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 15px;
    margin: 5px;
    font-size: 12pt;
}

.Share-Label {
    font-size: 13pt;
    color: #fff;
    cursor: pointer;
}

.DYK-Container {
    width: 100%;
    position: relative;
    top: 0px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    font-family: 'Lato';
    color: #b3b3b3;
    padding-bottom: 100px;
}

.DYK-Carousel {
    width: 100%;
    height: 60vh;
}

.DYK-CI {
    width: 96%;
    height: 55vh;
    margin-left: 2%;
    border-radius: 15px;
    object-fit: contain;
    
}

.carousel-caption {
    margin-top: 0px;
    position: relative;
    left: 0;
    right: 0;
}

