.LoaderButton {
  width: 100%;
  text-align: center;
  border: 2px solid #f52c74;
  background: linear-gradient(to bottom, #f52c74, #390417);
  border-radius: 20px;
  font-size: 18pt;
  font-family: 'Montserrat';
  font-weight: bold;
  color: #fff;
  padding: 10px;
  transition: .25s all ease;
}

.LoaderButton .spinning {
  margin-right: 7px;
  top: 2px;
  animation: spin 1s infinite linear;
  color: #fff;
}

.btn-primary:disabled {
  border: none;
}

.btn:not(:disabled):not(.disabled):hover {
  border-color: inherit;
  box-shadow: 0 0 5px #fff;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1) rotate(360deg);
  }
}

@-webkit-keyframes spin

/* Safari and Chrome */
  {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}