.BottomMenu-Container {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    z-index: 3;
    background-color: #0f142b;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.BottomMenu-Item {
    color: #fff;
    padding: 5px;
    margin-top: -15px;
    width: 80px;
    text-align: center;
    font-family: 'Montserrat';
    cursor: pointer;
}

.BottomMenu-Item-Text {
    font-size: 9pt;    
    margin-top: -5px;
}

.BottomMenu-Giglit-Container {
    width: 100px;
}

.BottomMenu-Giglit {
    position: relative;
    top: -15px;
    left: 5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #b3b3b3;
    text-align: center;
}

.BottomMenu-Giglit-Icon {
    font-size: 24pt;
    padding-top: 2px;

}

.BottomMenu-Item-Row {
    width: 100%;
    text-align: center;
}

.BottomMenu-Giglit-Red {
    background: radial-gradient(circle, #fff 5%, #f52c74);
    animation: blinkRed 1.2s infinite;
}

.BottomMenu-Giglit-Blue {
    background: radial-gradient(circle, #fff 5%, #190753);
    animation: blinkBlue 1.2s infinite;
}

.BottomMenu-Giglit-Pink {
    background: radial-gradient(circle, #fff 5%, #d233c2);
    animation: blinkPink 1.2s infinite;
}

@keyframes blinkRed {
    from { background: radial-gradient(circle, #fff 5%, #f52c74); }
    10% { background: radial-gradient(circle, #fff 10%, #f52c74); }
    20% { background: radial-gradient(circle, #fff 25%, #f52c74); }
    30% { background: radial-gradient(circle, #fff 35%, #f52c74); }
    40% { background: radial-gradient(circle, #fff 50%, #f52c74); }
    50% { background: radial-gradient(circle, #fff 60%, #f52c74); }
    60% { background: radial-gradient(circle, #fff 50%, #f52c74); }
    70% { background: radial-gradient(circle, #fff 35%, #f52c74); }
    80% { background: radial-gradient(circle, #fff 25%, #f52c74); }
    90% { background: radial-gradient(circle, #fff 10%, #f52c74); }
    to { background: radial-gradient(circle, #fff 0%, #f52c74); }
  }

  @keyframes blinkBlue {
    from { background: radial-gradient(circle, #fff 5%, #190753); }
    10% { background: radial-gradient(circle, #fff 10%, #190753); }
    20% { background: radial-gradient(circle, #fff 25%, #190753); }
    30% { background: radial-gradient(circle, #fff 35%, #190753); }
    40% { background: radial-gradient(circle, #fff 50%, #190753); }
    50% { background: radial-gradient(circle, #fff 60%, #190753); }
    60% { background: radial-gradient(circle, #fff 50%, #190753); }
    70% { background: radial-gradient(circle, #fff 35%, #190753); }
    80% { background: radial-gradient(circle, #fff 25%, #190753); }
    90% { background: radial-gradient(circle, #fff 10%, #190753); }
    to { background: radial-gradient(circle, #fff 0%, #190753); }
  }

  @keyframes blinkPink {
    from { background: radial-gradient(circle, #fff 5%, #d233c2); }
    10% { background: radial-gradient(circle, #fff 10%, #d233c2); }
    20% { background: radial-gradient(circle, #fff 25%, #d233c2); }
    30% { background: radial-gradient(circle, #fff 35%, #d233c2); }
    40% { background: radial-gradient(circle, #fff 50%, #d233c2); }
    50% { background: radial-gradient(circle, #fff 60%, #d233c2); }
    60% { background: radial-gradient(circle, #fff 50%, #d233c2); }
    70% { background: radial-gradient(circle, #fff 35%, #d233c2); }
    80% { background: radial-gradient(circle, #fff 25%, #d233c2); }
    90% { background: radial-gradient(circle, #fff 10%, #d233c2); }
    to { background: radial-gradient(circle, #fff 0%, #d233c2); }
  }  

  .Find-Container {
    width: 380px;
    max-height: 585px;
    position: fixed;
    bottom: 50px;
    left: 5px;
    border: 2px solid #b3b3b3;
    border-radius: 10px;
    box-shadow: 5px 5px 8px #000;
    background: linear-gradient(to bottom right, #190244, #534091);
    overflow-y: auto;
  }

  .Find-Flex-Spread {
    top: 0px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .Find-Top {
    width: 100%;
    padding: 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    font-size: 14pt;
    color: #fff;
  }

  .Find-Row {
    width: 100%;
    margin-bottom: 5px;
  }

  .Find-Row-Flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }

  .Find-Button {
    margin-top: 5px;
    height: 55px;
    padding: none;
  }

  .Find-Field {
    border-radius: 15px !important;
  }

  .Find-Cards-Container {
    position: relative;
    width: 100%;
    max-height: 300px;
    margin-bottom: 15px;
    overflow-y: hidden;
  }

  .Find-Cards {
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-bottom: 100px;
    overflow-y: auto;
  }

  .Find-Card-Selected {
    box-shadow: 0 0 10px #fff;
    border: 3px solid #fff;
    border-radius: 15px;
  }