.Calendar-Container {
    width: 100%;
    padding: 5px;
    color: #fff;
}

.Calendar-Buttons-Container {
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
}

.Calendar-Header {
    width: 210px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 11pt;
    transition: .25s all ease
}

.Calendar-Header-Text {
    cursor: pointer;
}

.Calendar-Header-Text:hover {
    text-shadow: 0 0 5px #000;
    font-size: 12pt;
}

.Calendar-Days-Header {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 245px;
    height: 30px;
    margin: 0 auto;
}

.Calendar-Days-Container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 245px;
    height: 180px;
    margin: 0 auto;
}

.Calendar-WD {
    width: 35px;
    height: 30px;
    font-size: 14pt;
    text-align: center;
}

.Calendar-Day {
    width: 35px;
    height: 30px;
    font-size: 12pt;
    text-align: center;
    border: 1px solid #b3b3b3;
    padding-top: 1px;
    cursor: pointer;
}

.Calendar-Day-Off {
    font-size: 11;
    color: #5e5e5e;
    cursor: default;
}

.Calendar-Day-Sel {
    border: 1px solid #fff;
    box-shadow: 0 0 5px #fff;
    text-shadow: 0 0 4px #fff;
    font-weight: bold;
}

.Calendar-Day-Event {
    background: linear-gradient(to bottom right, #0408fd, #14024e);
}

.Calendar-Day-Alt {
    background: linear-gradient(to bottom right, #fff, #282729);
}

.Calendar-Topleft {
    border-top-left-radius: 15px;
}

.Calendar-Topright {
    border-top-right-radius: 15px;
}

.Calendar-Bottomleft {
    border-bottom-left-radius: 15px;
}

.Calendar-Bottomright {
    border-bottom-right-radius: 15px;
}

.Calendar-Flex {
    display: flex;
    flex-wrap: nowrap;
}

.Info-Icon {
    font-size: 14pt;
    color: #fff;
    margin-left: 5px;
    margin-top: -3px;
    cursor: pointer;
    transition: .25s all ease;
}

.Info-Icon:hover {
    color: #777;
}

.Info-Container {
    position: absolute;
    width: 200px;
    background-color: #5f5e5e;
    box-shadow: 5px 5px 8px #000;
    border: 2px solid #000;
    border-radius: 15px;
    padding: 5px;
    z-index: 4;
}

.Info-Text {
    color: #fff;
    width: 100%;
    text-align: left;
    font-size: 12pt;
}

.Info-Row {
    width: 100%;
    margin-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.Info-Flex-Wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Info-Button {
    width: 85%;
    text-align: center;
    border-radius: 20px;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fff;
    padding: 10px;
    transition: .25s all ease;
    cursor: pointer;
    background: linear-gradient(180deg, #f52c74, #390417);
    border: 2px solid #f52c74;
}
