.Card-Container {
    width: 360px;
    height: 320px;
    border-radius: 15px;
    font-family: 'Lato';
}

.Card-event {
    border: 3px solid #f52c74;
    background: linear-gradient(to bottom right, #190244, #190753);
    border-radius: 15px;
}

.Card-venue {
    border: 3px solid #6e4ae0;
    background: linear-gradient(to bottom right, #190244, #190753);
    border-radius: 15px;
}

.Card-contributor {
    border: 3px solid #d233c2;
    background: linear-gradient(to bottom right, #190244, #190753);
    border-radius: 15px;
}

.Card-Header {
    position: relative;
    width: 100%;
    height: 65px;
    color: #fff;
    z-index: 1;
}

.Card-Title {
    font-size: 16pt;
    text-align: center;
    transition: .25s all ease;
}

.Card-Title:hover {
    text-shadow: 0 0 8px #f52c74;
    cursor: pointer;
}

.Card-Body {
    position: relative;
    width: 100%;
    height: 100px;
    color: #fff;
    z-index: 1;
}

.Card-Footer {
    position: relative;
    width: 100%;
    height: 151px;
    z-index: 1;
    margin-top: 0px;
}

.Card-Footer-Item {
    color: #fff;
    font-size: 10pt;
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    padding: 2px 2px;
    min-height: 28px;
}

.Card-Footer-Icon {
    margin: 0 5px;
}

.Card-Footer-Weather {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #190244;
    margin-right: 5px;
}

.Card-Row {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Card-Gigin {
    width: 200px;
    height: 35px;
    text-align: center;
    font-size: 14pt;
    color: #fff;
    font-family: 'Montserrat';
    font-weight: bold;
    background: linear-gradient(to bottom, #704ce3, #190364);
    border: 2px solid #fff;
    border-radius: 25px;   
    margin-left: 10px;
    cursor: pointer;
    transition: .25s all ease;
}

.Card-Gigin:hover {
    background: linear-gradient(to bottom, #190364, #704ce3);
    box-shadow: 0 0 5px #fff;
}

.Card-Gigin-In {
    box-shadow: 0 0 5px #fff;
}

.Card-Gigin-Icon {
    font-size: 28pt;
    color: #fff;
    margin-right: 15px;
    margin-top: -18px;
}

.Card-New-Section-Container {
    display: flex;
    width: 100%;
    padding: 0 10px;
}

.Card-New-Section-Title {
    margin-right: 10px;
    height: 20px;
    font-size: 10pt;
    color: #b3b3b3;
}

.Card-New-Section-Divider {
    flex-grow: 100;
    height: 1px;
    border: 1px solid #b3b3b3;
    margin-top: 10px;
    margin-right: 10px;
}

.Card-New-Section-Button {
    border: 1pt solid #b3b3b3;
    color: #b3b3b3;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    font-size: 8pt;
    transition: .25s all ease;
    margin: 5px;
    height: 30px;
}

.Card-New-Section-Button:hover {
    color: #fff;
    font-weight: bold;
}

.Card-Textbox {
    width: 245px;
    margin-left: 10px;
    transition: .25s all ease;
    color: #fff;
    padding: 5px;
    font-size: 10pt;
    z-index: 2;
}

.Card-Textbox-Min {
    height: 70px;
    overflow-y: hidden;
}

.Card-Textbox-Max {
    height: 82px;
    overflow-y: auto;
    background-color: rgba(83, 81, 81, 0.7);
    border-radius: 15px;
}

.Card-CI {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: fill;
    opacity: 1;
    margin-right: 5px;
}

.Card-CI-Text {
    width: 50px;
    text-align: center;
    font-size: 7pt;
}

.Card-BG {
    position: relative;
    height: 0px;
    top: -275px;
    z-index: 0;
    opacity: .6;
}

.Card-BG-Icon {
    position: relative;
    top: 50px;
    font-size: 96pt;
    text-align: center;
    color: #fff;
    opacity: .6;
}

.Card-BG-CI {
    height: 270px;
    width: 375px;
    border-radius: 25px;
    object-fit: cover;
    margin: 0 auto;
}

.Card-Stars {
    font-size: 23pt;
    color: yellow;
    margin-top: -6px; 
    margin-right: 3px;   
}

.Card-Stars-Text {
    margin-left: 7px;
    font-size: 9pt;
    font-weight: bold;
    margin-top: -27px;
    color: #000;
}

.Card-Heart-Text {
    margin-left: 0px;
    font-size: 9pt;
    font-weight: bold;
    margin-top: -27px;
    color: #000;
    text-align: center;
    width: 30px;
}

.Card-Heart {
    font-size: 22pt;
    color: #f52c74;
    margin-top: -10px; 
    margin-left: 5px;   
}

.Card-Icon {
    font-size: 22pt;
}

.Card-TopLeft {
    margin-top: -5px;
    margin-left: -5px;
}

.Card-Nearby {
    border: 2px solid #f52c74;
    border-radius: 15px;
    padding: 2px;
    height: 22px;
    margin-right: 5px;
}

.Card-Hiring {
    animation: hiring 1.5s infinite linear;
}

@keyframes hiring {
    from {
        border: 1px solid #190753;
        box-shadow: 0 0 5px #190753;
    }

    50% {
        border: 1px solid #190753;
        box-shadow: 0 0 15px #fff;
    }

    to {
        border: 1px solid #190753;
        box-shadow: 0 0 5px #190753;
    }
}