.TopMenu-Container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    z-index: 16;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.TopMenu-BG-fan {
    background: linear-gradient(to bottom, #704ce3, #190753);
}

.TopMenu-BG-Venue {
    background: linear-gradient(to bottom, #190244, #190753);
}

.TopMenu-BG-Contributor {
    background: linear-gradient(to bottom, #f52c74, #390417);
}

.TopMenu-BG-tix {
    background: linear-gradient(to bottom, #435f02, #d2f77d);
}

.TopMenu-BG-contactus {
    background: linear-gradient(to bottom, #3d3d3d, #d9d9da);
}

.TopMenu-Search {
    width: 50%;
}

.TopMenu-Profile {
    width: 40px;
    height: 40px;
    padding: 1px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
}

.TopMenu-Profile-fan {
    border: 1px solid #f52c74;
    box-shadow: 0 0 5px #f52c74;
}

.TopMenu-Profile-venue {
    border: 1px solid #f52c74;
    box-shadow: 0 0 5px #f52c74;
}

.TopMenu-Profile-contributor {
    border: 1px solid #f52c74;
    box-shadow: 0 0 5px #f52c74;
}

.TopMenu-Left {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
}

.TopMenu-Right {
    display: flex;
    flex-wrap: nowrap;
    justify-content: right;
    margin-right: 5px;
}

.TopMenu-Item {
    color: #fff;
    padding: 8px;
    margin: 3px;
    text-align: center;
    font-size: 18pt;
    cursor: pointer;
}

.TopMenu-Item-Sel {
    border-bottom: 2px solid #fff;
}

.TopMenu-Notifications {
    position: relative;
    width: 20px;
    background-color: #ff0000;
    border-radius: 50%;
    color: #fff;
    font-size: 8pt;
    font-weight: bold;
    top: -30px;
    left: 10px;
    z-index: 0;
}

.TopMenu-Img {
    width: 40px;
    height: 40px;
    padding: 1px;
    margin: 5px;
    border: 1px solid #f52c74;
    border-radius: 50%;
    box-shadow: 0 0 5px #f52c74;
    cursor: pointer;
}

.TopMenu-User {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-top: 5px;
    border: 1px solid #f52c74;
    border-radius: 50%;
    box-shadow: 0 0 5px #f52c74;
    cursor: pointer;
    padding: 5px;
    text-align: center;
    font-family: "Lato";
}

.TopMenu-User svg {
    font-size: 27pt;
    margin-top: -4px;
    margin-left: -4px;
}

.Settings-Container {
    width: 300px;
    height: 320px;
    position: absolute;
    top: 51px;
    right: 30px;
    padding-top: 15px;
    border-radius: 30px;
    border: 2px solid #b3b3b3;
    box-shadow: 5px 5px 8px #000;
    background: linear-gradient(to bottom right, #190244, #534091);
    transition: .25s all ease;
    z-index: 2;
}

.Settings-Row {
    width: 100%;
    margin-bottom: 10px;
}

.Settings-Button {
    width: 230px;
    margin: 0 auto;
    border: 1px solid #b3b3b3;
    border-radius: 25px;
    padding: 5px;
    text-align: center;
    font-family: "Montserrat";
    font-size: 14pt;
    font-weight: bold;
    color: #b3b3b3;
    cursor: pointer;
}

.Settings-Button:hover {
    box-shadow: 0 0 8px #fff;
}

.Settings-Venue {
    border: 3px solid #6e4ae0;
}

.Settings-Contributor {
    border: 3px solid #f52c74;
}

.Settings-Fan {
    border: 1px solid #f1b9f9;
}

.Settings-Contactus {
    border: 3px solid #190753;
}

.Settings-Tix {
    border: 3px solid rgb(202, 202, 20);
}

.Settings-About {
    border: 3px solid rgb(234, 228, 252);
}

.Settings-Current {
    box-shadow: 0 0 8px #fff;
}

.Settings-User {
    color: #000;
    background-color: #b3b3b3;
}

.Settings-Foul {
    width: 100%;
    margin-top: -0px;
    text-align: center;
    font-size: 12pt;
    color: #fff;
}

.Settings-Link {
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;
    text-shadow: 1px 0px 1px #b3b3b3;
}

.Settings-Link-Red {
    color: #f52c74;
}

.Settings-Link-Blue {
    color: #190753;
}

.Settings-Link:hover {
    font-size: 16pt;
}

.Search-Location-Checkmark {
    height: 20px;
    margin-top: 0px;
}

.Search-Container {
    width: 375px;
    position: absolute;
    top: 51px;
    left: 5px;
    padding: 5px 15px;
    padding-bottom: 105px;
    border-radius: 15px;
    border: 2px solid #b3b3b3;
    box-shadow: 5px 5px 8px #000;
    background: linear-gradient(to bottom right, #190244, #534091);
    max-height: calc(100vh - 105px);
    overflow-y: auto;
}

.Search-Row {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.Search-Label {
    font-size: 14pt;
    color: #fff;
    cursor: pointer;
}

.Search-Link {
    margin-top: 3px;
    font-size: 9pt;
    color: #fff;
    text-align: left;
    text-decoration: underline;
    cursor: pointer;
    transition: .25s all ease;
}

.Search-Link:hover {
    font-size: 10pt;
    font-weight: bold;
    color: #777;
}

.Search-Flex {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
}

.Search-Spread {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Lodging-Spread {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 5px;
}

.Lodging-Icon {
    font-size: 13pt;
    margin-right: 5px;
    animation: .25s all ease;
}

.Lodging-Icon:hover {
    color: #777;
}

.Lodging-Unselected {
    color: #fff;
}

.Lodging-Selected {
    color: #cffa0e;
}


.Info-Icon {
    font-size: 14pt;
    color: #fff;
    margin-left: 5px;
    margin-top: -3px;
    cursor: pointer;
    transition: .25s all ease;
    animation: infoFlash 1.2s infinite;
}

.Info-Icon:hover {
    color: #777;
}

.Info-Container {
    position: absolute;
    width: 200px;
    background-color: #5f5e5e;
    box-shadow: 5px 5px 8px #000;
    border: 2px solid #000;
    border-radius: 15px;
    padding: 5px;
    z-index: 4;
}

.Info-Text {
    color: #fff;
    width: 100%;
    text-align: left;
    font-size: 12pt;
}

.Info-Row {
    width: 100%;
    margin-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.Info-Flex-Wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.Info-Button {
    width: 85%;
    text-align: center;
    border-radius: 20px;
    font-size: 14pt;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #fff;
    padding: 10px;
    transition: .25s all ease;
    cursor: pointer;
    background: linear-gradient(180deg, #f52c74, #390417);
    border: 2px solid #f52c74;
}

@keyframes infoFlash {
    from {
        color: #fff;
    }

    50% {
        color: #190244;
    }

    to {
        color: #fff;
    }
}

@property --bg-angle {
    inherits: false;
    initial-value: 0deg;
    syntax: "<angle>";
}

.Profile-Img {
    margin-top: 3px;
}

.Profile-Img-Border {
    animation: profile 2.5s infinite linear;
    border-radius: 50%;
    padding: 1rem;
    width: 44px;
    height: 44px;
    background: linear-gradient(to bottom,
            oklch(0.1 0.2 240 / 0.95),
            oklch(0.1 0.2 240 / 0.95)) padding-box,
        conic-gradient(from var(--bg-angle) in oklch longer hue,
            oklch(1 0.24 60deg) 0 0) border-box;
    border: 2px solid transparent;
}

.Profile-Img-Size {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    left: -10px;
    top: -10px;
}

.Profile-Premium {
    animation: profileFlash 1.5s infinite linear;
}

@keyframes profile {
    to {
        --bg-angle: 360deg;
    }
}

@keyframes profileFlash {
    from {
        border: 1px solid #f52c74;
        box-shadow: 0 0 5px #f52c74;
    }

    50% {
        border: 1px solid #f52c74;
        box-shadow: 0 0 15px #fff;
    }

    to {
        border: 1px solid #f52c74;
        box-shadow: 0 0 5px #f52c74;
    }
}

.Bounce {
    animation: bounce 2s ease infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-12px); }
    60% {transform: translateY(-6px); }
}